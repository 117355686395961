import notFound from '../../images/404.png';
import './notfound.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Notfound(){
    let {t} =useTranslation() 
    return(
        <div className='mainWrapper contactUs'>
            <div className='d-flex notFoundpage flex-column justify-content-center align-items-center '>
                <img src={notFound} className='notfoundImg' alt='not found'/>
                <h1 className='notfoundHeadline'>{t('Looks like youre lost')}</h1>
                <h5 className='fifthslidePar'>{t('The page you are looking for is not available.')}</h5>
                <Link className='homeLink' to={`${process.env.REACT_APP_BASENAME}`}> 
                <span >{t('GO HOME')}</span> 
                    <svg className='mx-3' width="35" height="30" viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M-6.50324e-07 15.0002C-6.74959e-07 14.4367 0.223883 13.8962 0.622398 13.4976C1.02091 13.0991 1.56141 12.8752 2.125 12.8752L26.7452 12.8752L17.6205 3.75474C17.4229 3.55717 17.2662 3.32262 17.1593 3.06447C17.0523 2.80633 16.9973 2.52965 16.9973 2.25024C16.9973 1.97083 17.0523 1.69416 17.1593 1.43601C17.2662 1.17787 17.4229 0.943317 17.6205 0.745744C17.8181 0.548169 18.0526 0.391444 18.3108 0.284518C18.5689 0.177592 18.8456 0.12256 19.125 0.12256C19.4044 0.12256 19.6811 0.177592 19.9392 0.284518C20.1974 0.391444 20.4319 0.548169 20.6295 0.745744L33.3795 13.4957C33.5774 13.6931 33.7344 13.9276 33.8415 14.1858C33.9487 14.444 34.0038 14.7207 34.0038 15.0002C34.0038 15.2798 33.9487 15.5565 33.8415 15.8147C33.7344 16.0729 33.5774 16.3073 33.3795 16.5047L20.6295 29.2547C20.4319 29.4523 20.1974 29.609 19.9392 29.716C19.6811 29.8229 19.4044 29.8779 19.125 29.8779C18.8456 29.8779 18.5689 29.8229 18.3108 29.716C18.0526 29.609 17.8181 29.4523 17.6205 29.2547C17.2215 28.8557 16.9973 28.3145 16.9973 27.7502C16.9973 27.4708 17.0523 27.1942 17.1593 26.936C17.2662 26.6779 17.4229 26.4433 17.6205 26.2457L26.7453 17.1252L2.125 17.1252C1.56141 17.1252 1.02091 16.9014 0.622398 16.5028C0.223883 16.1043 -6.25689e-07 15.5638 -6.50324e-07 15.0002Z" fill="#A58C3B"/>
                    </svg>
                </Link>
            </div>
        </div>
    )
}