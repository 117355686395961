import './home.css';
import buildings from '../../images/building2.png';
// import video from '../../images/video.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FreeMode, Pagination, Navigation } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import { WOW } from 'wowjs';
// import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { getHome, getHomeFirstSlide, getServices, getTestimonials } from '../../api/home';
import { filterHeaders, filterLanguage } from '../../util/filterLanguage';
// import ReactPlayer from 'react-player/youtube';
import { getProjects } from '../../api/projects';
import leftArrow from '../../images/leftArrow.svg';
import rightArrow from '../../images/rightArrow.svg';
import ClientCard from '../clientCard/clientCard';

export default function HomeMobile() {
    let [width, setwidth] = useState(window.innerWidth)
    let [firstSlide, setFirstSilde] = useState({});
    let [services, setServices] = useState([]);
    let [projects, setProjects] = useState([]);
    let [testimonials, setTestimonials] = useState([]);
    let [homeHeaders, setHomeheaders] = useState();
    const { t, i18n } = useTranslation();
    const basename = i18n.language;
    // to set the loop for desktop view true and mobile view false
    useEffect(() => {
        setwidth(window.innerWidth);
    }, [width])

    useEffect(() => {
        const wow = new WOW({ live: false });
        wow.init();
    }, [])

    // first slide
    useEffect(() => {
        getHomeFirstSlide().then((res) => {
            let firstSlidedata = res.data.data
            setFirstSilde(firstSlidedata)
        })
        // get services
        getServices().then((res) => {
            let services = res.data.data
            setServices(services)
        })
        getProjects(9).then((res) => {
            let data = res.data.data;
            setProjects(data)
        })
        // get testimonials
        getTestimonials().then((res) => {
            let testimonials = res.data.data;
            setTestimonials(testimonials)
        })
        getHome().then((res) => {
            setHomeheaders(res.data.data)
        })
    }, [])



    return (
        <div className='mainWrapper homeWrapper d-xl-none'>

            <div >
                {/***********  first slide video ******************* */}
                <div className=' position-relative carsouleItem firstSlide'>
                    <div className=' d-flex flex-column justify-content-center '>
                        <p className='homeHeadline wow animate__bounce' data-wow-duration='2s'>{firstSlide && firstSlide?.locales && firstSlide?.locales.length > 0 && filterLanguage(firstSlide)[0].title}</p>
                        <p className='homeWord'>{firstSlide && firstSlide?.locales && firstSlide?.locales.length > 0 && filterLanguage(firstSlide)[0].description}</p>

                    </div>

                    {/* <div className=' position-relative videoWrapper'>
                        <ReactPlayer width="100%" height="100%" url={firstSlide.youtube_url} playing
                            playIcon={<img src={video} className="videoIcon" alt='' />} light={buildings} />
                    </div> */}
                    <div className='videoWrapper' style={{backgroundImage:`url(${buildings})`}}>

                    </div>
                    <div className='d-flex justify-content-center'>
                        <Link to={`/${basename}/contact-us`} className='defaultBtn firstSlideBtn '>
                            <span>{t('Grow Your Business')}</span>
                            <svg className='ms-3' width="15" height="16" viewBox="0 0 18 16" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.5 8.00012C0.5 7.71833 0.611942 7.44808 0.811199 7.24882C1.01046 7.04956 1.28071 6.93762 1.5625 6.93762L13.8726 6.93762L9.31025 2.37737C9.21146 2.27859 9.1331 2.16131 9.07964 2.03224C9.02617 1.90317 8.99866 1.76483 8.99866 1.62512C8.99866 1.48542 9.02617 1.34708 9.07964 1.21801C9.1331 1.08894 9.21146 0.971658 9.31025 0.872872C9.40904 0.774085 9.52631 0.695722 9.65538 0.642259C9.78446 0.588796 9.92279 0.56128 10.0625 0.56128C10.2022 0.56128 10.3405 0.588796 10.4696 0.642259C10.5987 0.695722 10.716 0.774085 10.8148 0.872872L17.1898 7.24787C17.2887 7.34657 17.3672 7.46382 17.4208 7.5929C17.4743 7.72198 17.5019 7.86037 17.5019 8.00012C17.5019 8.13988 17.4743 8.27826 17.4208 8.40734C17.3672 8.53643 17.2887 8.65367 17.1898 8.75237L10.8148 15.1274C10.716 15.2262 10.5987 15.3045 10.4696 15.358C10.3405 15.4114 10.2022 15.439 10.0625 15.439C9.92279 15.439 9.78446 15.4114 9.65539 15.358C9.52631 15.3045 9.40904 15.2262 9.31025 15.1274C9.11074 14.9279 8.99866 14.6573 8.99866 14.3751C8.99866 14.2354 9.02617 14.0971 9.07964 13.968C9.1331 13.8389 9.21146 13.7217 9.31025 13.6229L13.8726 9.06262L1.5625 9.06262C1.28071 9.06262 1.01046 8.95068 0.811199 8.75142C0.611942 8.55217 0.5 8.28191 0.5 8.00012Z" fill="#fff" />
                            </svg>
                        </Link>
                    </div>

                </div>

                {/***********  second slide Our services ******************* */}

                <div className=''>
                    <div className=' splitter'>
                        <span>{t('Our Services')}</span>
                    </div>
                    <div className="nav flex-column nav-pills align-items-center" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {/* eslint-disable-next-line */}
                        {services && services.length > 0 && services.map((service, index) => {
                            if (index < 3) {
                                return (
                                    <div key={index} className="d-flex align-items-center servicesNavtabs servicesNavtabsLarge" >
                                        <img src={service.image?.webp} alt='' />

                                        <span className='mx-2'>{service && service?.locales && service?.locales.length > 0 && filterLanguage(service)[0].title}</span>
                                    </div>
                                )
                            }
                        })}

                        <Link to={`/${basename}/services`} className='defaultBtn firstSlideBtn'>
                            {t('View all services')}
                        </Link>

                    </div>
                </div>

                {/***********  third slide Our latest work ******************* */}
                <div>
                    <div className=' splitter'>
                        <span>{t('Recent Projects')}</span>
                    </div>
                    <div className='d-flex flex-column w-100 justify-content-start justify-content-lg-center py-0  carsouleItem position-relative'>
                        <div className='position-relative projectswiper'>
                            <div className='swiper-custom-button-prev'>
                                <img src={leftArrow} alt='' />
                            </div>
                            <div className='swiper-custom-button-next'>
                                <img src={rightArrow} alt='' />
                            </div>

                            <Swiper
                                loop={true}
                                // centeredSlides={true}
                                initialSlide={1}
                                slidesPerView={3}
                                navigation={{
                                    nextEl: ".swiper-custom-button-next",
                                    prevEl: ".swiper-custom-button-prev",
                                }}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                    lazyLoad: true,
                                }}
                                modules={[FreeMode, Autoplay, Navigation]}
                                className='cardsSwiper'
                                breakpoints={{
                                    100: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                        centeredSlides: true
                                    },
                                    500: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                        centeredSlides: true
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 10
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                        centeredSlides: false
                                    },
                                    1200: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                        centeredSlides: false
                                    }
                                }}
                            >
                                {projects && projects.length && projects.map((item, index) => {
                                    return (

                                        <SwiperSlide key={index}>
                                            <div key={index} className='cards' >
                                                <img src={item.image?.webp} className='cardsImg w-100' alt='project' />
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}

                            </Swiper>
                        </div>
                        <div className='d-flex justify-content-center justify-content-lg-start'>
                            <Link className='defaultBtn thirdSildebtn ' to={`/${basename}/our-projects`}>{t('View More')}</Link>
                        </div>
                    </div>
                </div>
                {/***********  fourth slide Our clients review ******************* */}
                <div>
                    <div className='d-xxl-none testimonialsSmall'>
                        <div className=' splitter'>
                            <span>{t('Testimonials')}</span>
                        </div>
                        <div className='seconslideHeade clinetsHeader'>
                            <p>{t('What our')} {t('clients say about us')}</p>
                        </div>
                        <div className='reviewsSliderWrapper'>
                            <Swiper
                                slidesPerView={3}
                                spaceBetween={20}
                                loop={false}
                                freeMode={true}
                                pagination={true}
                                centeredSlides={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                    pauseOnHover: true,
                                    lazyLoad: true,
                                }}
                                modules={[FreeMode, Pagination, Autoplay]}
                                className='reviewsSlider'
                                breakpoints={{
                                    100: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                        centeredSlides: true
                                    },
                                    500: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                        centeredSlides: true
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 50,
                                        centeredSlides: true
                                    },
                                    992: {
                                        slidesPerView: 2,
                                        spaceBetween: 50,
                                        centeredSlides: true
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 50,
                                        centeredSlides: true
                                    },
                                    1300: {
                                        slidesPerView: 2,
                                        spaceBetween: 50,
                                        centeredSlides: true
                                    }
                                }}
                            >
                                {testimonials && testimonials.length && testimonials.map((item, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <ClientCard item={item} />
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>

                    </div>
                </div>
                {/***********  Last slide Our ******************* */}

                <div className=' splitter'>
                    <span>{t('Our Clients')}</span>
                </div>
                <div className='d-flex justify-content-around align-items-center lastSlide flex-column flex-lg-row'>
                    <section className='text-center col-12 col-lg-5 pb-5'>
                        <h1 className='seconslideHeade'>
                            {homeHeaders?.home_we_work_for && filterHeaders(homeHeaders?.home_we_work_for)[0].title}
                        </h1>
                        <p className='fifthslidePar d-none d-lg-block'>
                            {homeHeaders?.home_we_work_for && filterHeaders(homeHeaders?.home_we_work_for)[0].description}
                        </p>
                        <div className=' d-none d-lg-flex justify-content-center '>
                            <button className='defaultBtn thirdSildebtn mx-0'>{t('View Case Studies')}</button>
                        </div>
                    </section>
                    <div className='col-12 col-lg-5 circular-projects pb-5 wow animate__flipInY' data-wow-duration='2s'>
                        {projects && projects.length && projects.map((item, index) => {
                            return (
                                <div key={index} className='project'><img src={item.image?.webp} alt='' /></div>
                            )
                        })}
                    </div>
                </div>
            </div>


        </div>
    )
}