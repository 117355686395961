import React, { useState } from 'react';
import { useEffect } from 'react';
import { getPrivacyPolicy } from '../../api/pages';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { filterHeaders } from '../../util/filterLanguage';
import parse from 'html-react-parser';

export default function PrivacyPolicy() {
    let [privacydata, setPrivacyData] = useState()
    let { t } = useTranslation()

    useEffect(() => {
        getPrivacyPolicy().then((res) => {
            setPrivacyData(res.data.data)
        })
    }, [])

    return (
        <div className=' aboutusWrapper position-relative contactUs pt-0'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dubisign |{t('privacy policy')}</title>
            </Helmet>
            <div className='mainWrapper'>
                <h1 className='homeHeadline'>
                    {privacydata?.locales && filterHeaders(privacydata?.locales)[0].title}
                </h1>
                <div className='about-us-info'>
                    <div className='homeWord '>
                        {privacydata?.locales && parse(filterHeaders(privacydata?.locales)[0].description)}
                    </div>
                </div>
            </div>
        </div>
    )
}
