import { filterLanguage } from '../../util/filterLanguage';
import './services.css';
import parse from 'html-react-parser';

export default function MobileServices({ services, serviceHeaders }) {
    function closeOther(e) {
        var elems = document.querySelectorAll(".collapsedService");
        [].forEach.call(elems, function (el) {
            el.classList.remove("show");
        });
        let x=document.getElementById(e)
        x.classList.add = "show"
    }

    return (
        <div className='mainWrapper position-relative contactUs mobileWrapper mobileServices d-lg-none' >
            <h1 className='servicesHeadline'>
                {serviceHeaders && serviceHeaders?.locales && serviceHeaders?.locales.length > 0 && filterLanguage(serviceHeaders)[0].title}
            </h1>
            {services && services.length > 0 ? services.map((service, index) => {
                return (
                    <button key={index} onClick={()=>closeOther(`#firstCollapse${service.id}`)} className=" servicesNavtabs servicesNavtabsLarge w-100" data-bs-toggle="collapse" data-bs-target={`#firstCollapse${service.id}`} type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <div className='d-flex align-items-center'>
                            <img src={service?.image.webp} alt="" className='servicesIcon' />
                            <span className='mx-2'>
                                {service && service?.locales && service?.locales.length > 0 && filterLanguage(service)[0].title}
                            </span>
                        </div>
                        <div className="collapse collapsedService mt-5" id={`firstCollapse${service.id}`}>

                            {service && service.locales && service.locales.length > 0 && parse(filterLanguage(service)[0].description)}

                        </div>
                    </button>
                )
            }) : ''}
        </div>
    )
}