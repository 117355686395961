import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import React, { useState } from 'react';
import { mapKey } from '../../config/config';
export default function Map({ location }) {
    const [map, setMap] = useState(null);
    const [libraries] = useState(['places']);
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);
    const containerStyle = {
        width: "100%",
        height: "324px"
    };
    const containerStyleSmall = {
        width: "100%",
        height: "230px"
    };
    return (

        <div className={`MapContainer`}>
            <LoadScript id="script-loader" googleMapsApiKey={mapKey}
                libraries={libraries}>
                <GoogleMap mapContainerStyle={window.innerWidth >1024 ? containerStyle : containerStyleSmall} center={location} zoom={20} onUnmount={onUnmount} draggable={false}>
                    <Marker position={location} draggable={false} />
                </GoogleMap>
            </LoadScript>
        </div>

    )
}
