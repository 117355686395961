import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import './Offers.scss';
import OfferCard from './offerCard';
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import package1 from '../../images/package1.svg'
import package2 from '../../images/package2.svg'
import package3 from '../../images/package3.svg'

export default function Offers() {
    let { t } = useTranslation()
    let [offers] = useState([
        {
            image: package1, name: t('Basic Package'), desc: "",
            features: [
                t('Introductory website'),
                t('Home Pages with 4 Inner Pages'),
                t('EN & AR Languages'),
                t('Hosting , Domain reservation , Official email'),
                t('Dynamic dashboard'),
                t('SSL security certificate , Integrated protection'),
                t('Preparing the website for search engines'),
                t('Linking social network pages to the site'),
                t('Adding contact info, location'),
                t('Offering data entry services for 1 time'),
                t('1 month technical support')
            ]
        },

        {
            image: package2, name: t('Advanced Package'), desc: "",
            features: [
                t('Introductory website using Special programming'),
                t('Home Pages with 8 Inner Pages'),
                t('Responsive design with the colors of your choice'),
                t('EN & AR Languages'),
                t("Hosting , Domain reservation , 3 Official emails"),
                t('Dynamic dashboard'),
                t('SSL security certificate , Integrated protection'),
                t('Preparing the website for search engines'),
                t('Linking social network pages to the site'),
                t('Adding contact info, location'),
                t('Offering data entry services for 1 time'),
                t('3 months technical support')
            ]
        }, {
            image: package3, name: t('Professional Package'), desc: "",
            features: [
                t('Implementing all the required ideas'),
                t('Integrated technical analysis'),
                t('Professional Responsive design with the colors of your choice'),
                t('Professional logo design'),
                t('Unlimited website pages'),
                t('EN & AR with the possibility of adding a third language'),
                t('Hosting , Domain reservation , unlimited Official emails'),
                t('Dynamic dashboard'),
                t('SSL security certificate , Integrated protection'),
                t('Preparing the website for search engines'),
                t('Linking social network pages to the site'),
                t('Offering data entry services for 1 time'),
                t('6 months technical support')
            ]
        }
    ])

    return (
        <div className='offersPage'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dubisign | {t('Offers')}</title>
            </Helmet>
            <div className='splitter'>
                <span>{t('Our Packages')}</span>
            </div>
            <div className='mainWrapper pagesWrapper'>
                <section className='row row-cols-1 row-cols-md-2 row-cols-xl-3'>
                    {offers && offers.map((x, i) => {
                        return (
                            <div className='offerItem col' key={i}>
                                <OfferCard t={t} offer={x} />
                            </div>
                        )
                    })}
                </section>
                {/* <Swiper
                    dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                    slidesPerView={3}
                    autoHeight={false}
                    className="offersSwiper"
                    // grabCursor={true}
                    loop={true}
                    // speed={2000}
                    observer={false}
                    centeredSlides={true}
                    spaceBetween={50}
                    breakpoints={{
                        1900: {
                            spaceBetween: 50,
                            slidesPerView: 3,
                            loop: true,
                            centeredSlides: true
                        },
                        1500: {
                            spaceBetween: 50,
                            slidesPerView: 3,
                            loop: true,
                            centeredSlides: true
                        },
                        1200: {
                            spaceBetween: 30,
                            slidesPerView: 3,
                            loop: true,
                            centeredSlides: true
                        },
                        992: {
                            spaceBetween: 30,
                            slidesPerView: 2,
                            loop: false,
                            centeredSlides: false
                        },
                        750: {
                            spaceBetween: 30,
                            slidesPerView: 2,
                            loop: false,
                            centeredSlides: false
                        },
                        200: {
                            spaceBetween: 30,
                            slidesPerView: 1,
                            loop: false,
                            centeredSlides: false
                        },

                    }}>
                    {offers && offers.map((x, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <OfferCard t={t} offer={x} />
                            </SwiperSlide>
                        )
                    })}

                </Swiper> */}
            </div>
        </div>
    )
}
