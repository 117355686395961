import { ThemeContext, themes } from '../contexts/theme';
import './footer.css';
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import englishImg from '../../images/english.webp'
import arabicImg from '../../images/uae.svg'
// import { useNavigate } from 'react-router-dom';
export default function Footer({ socials }) {
    const [darkModee, setdarkModee] = useState();
    const [Langs] = useState([
        { slug: 'en', img: englishImg, name: 'English' },
        { slug: 'ar', img: arabicImg, name: 'Arabic' }
    ]);
    const { theme, changeTheme } = useContext(ThemeContext);
    let { i18n } = useTranslation()
    useEffect(() => {
        if (theme === 'light') {
            setdarkModee(false)
        } else {
            setdarkModee(true)
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (darkModee) {
            changeTheme(themes.dark);
            localStorage.setItem('theme', 'dark')
            document.getElementById("myCheck1").checked = true;
        } else {
            changeTheme(themes.light);
            localStorage.setItem('theme', 'light')
            document.getElementById("myCheck1").checked = false;
        }
        //eslint-disable-next-line
    }, [darkModee]);
    // let navigate = useNavigate()
    return (
        <footer className='mainWrapper footerWrapper'>
            <div className='justify-content-between d-flex'>
                {socials && socials.map(soc => {
                    // if (soc.id !== 17)
                     return (
                        <a target="_blank" className='socialIcons' key={soc?.id} href={soc?.link} rel="noreferrer">
                            <img alt="link" src={soc?.image?.url} />
                        </a>
                    )
                    // return true;
                })}
            </div>

            <div className="d-flex align-items-center">
                <span className='socialIcons d-none d-lg-flex ms-2'>
                    <label className="switch">
                        <input className='themeToggler' id='myCheck1' type="checkbox" onClick={() => {
                            setdarkModee(!darkModee)
                        }} />
                        <span className="slider round"></span>
                    </label>
                </span>
                <span className='socialIcons footerFont d-none d-lg-flex'>
                    {/* eslint-disable-next-line */}
                    {Langs && Langs.map((lang, index) => {
                        if (i18n.language !== lang.slug) {
                            return (
                                <div key={index} className='langToggler' onClick={() => i18n.changeLanguage(lang.slug)}>
                                    <img className='flagImg' src={lang.img} alt='' />
                                    <span className='mx-2'>{lang.name}</span>
                                </div>
                            )
                        }
                    })}
                </span>
                <span className='footerFont'>Dubisign All Rights Reserved, 2021.</span>
            </div>
        </footer>
    )
}
