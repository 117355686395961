import './projects.css';
import fullEllipse from '../../images/fullEllipse.png';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { FreeMode, Navigation } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import CaseStudy from './caseStudy';
import React, { useState, useEffect } from 'react';
import { filterLanguage } from '../../util/filterLanguage';
import { getServices } from '../../api/home';
import { getServiceProject } from '../../api/projects';
import { useTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";

export default function Projects() {
    const [services, setServices] = useState([]);
    const [projects, setprojects] = useState([]);
    const [projectsLength, setprojectsLength] = useState();
    const [perPage, setperPage] = useState(6);
    let { t } = useTranslation()
    function fetchProjectServices(perPage,serviceId) {
        getServiceProject(perPage, serviceId).then((res) => {
            let projects = res.data.data;
            setprojectsLength(res.data.pagination.total)
            setprojects(projects)
        })
    }
    useEffect(()=>{
        fetchProjectServices(perPage,'')
    },[perPage])
    useEffect(() => {
        getServices().then((res) => {
            let services = res.data.data
            setServices(services)
        })
    }, [])
    function handleChecked(e, id) {
        var elems = document.querySelectorAll(".activeProject");
        [].forEach.call(elems, function (el) {
            el.classList.remove("activeProject");
        });
        e.target.className = "activeProject"
        fetchProjectServices(6,id)
    }


    return (
        <div className='mainWrapper position-relative contactUs allProjects mt-3'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dubisign | {t('Projects')}</title>
            </Helmet>
            <nav>
                <Swiper
                    spaceBetween={20}
                    slidesPerView={6}
                    loop={false}
                    freeMode={true}
                    modules={[Navigation, FreeMode]}
                    className='filterSwiper'
                    breakpoints={{
                        100: {
                            slidesPerView: 1,
                            spaceBetween: 1,
                            navigation: true
                        },
                        400: {
                            slidesPerView: 1.5,
                            spaceBetween: 1,
                            navigation: true,
                            centeredSlides:true
                        },
                        500: {
                            slidesPerView: 2,
                            spaceBetween: 1,
                            navigation: true,
                            centeredSlides:true
                        },
                        768: {
                            slidesPerView: 3,
                            initialSlide:0,
                            centeredSlides:true
                        },
                        1045: {
                            slidesPerView: 3,
                            initialSlide:0
                        },
                        1400: {
                            slidesPerView: 4,
                        },
                        1550: {
                            slidesPerView: 5,
                        },
                        1600: {
                            slidesPerView: 6,
                    spaceBetween:50

                        }
                    }}
                >
                    {services && services.length > 0 ?
                        <>
                            <SwiperSlide className='text-center'>
                                <span className='navItems activeProject' onClick={(e) => handleChecked(e, '')}>{t('all projects')}</span>
                            </SwiperSlide>
                            {services.map((service, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className='d-flex justify-content-center align-items-center'>
                                        <span className='navItems' onClick={(e) => handleChecked(e, service.id)}>
                                            {service && service?.locales && service?.locales.length > 0 && filterLanguage(service)[0].title}
                                        </span>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </>
                        : ''}
                </Swiper>
            </nav>
            <section className='projectsWrapper'>
                <div className='row row-cols-1 row-cols-md-2 row-cols-xl-3 mt-5 g-5 px-5'>
                    {projects && projects.length > 0 ? projects.map((project, index) => {
                        return (
                            <div key={index} className='col'>
                                <div className='cards allprojetcs  position-relative' style={{ backgroundImage: `url(${project?.image.webp})` }} /* data-bs-toggle="modal" data-bs-target="#exampleModal" */>
                                    <div className='projectTitle'>
                                        {project && project?.locales && project?.locales.length > 0 && filterLanguage(project)[0].title}
                                    </div>
                                </div>
                            </div>

                        )
                    }) : ''}
                </div>
                {projects && projects.length > 0 && projects.length !== projectsLength ?   
                <div className='paginationBtn px-lg-5'>
                    <button className='defaultBtn' onClick={()=>setperPage(perPage + 6)}>
                        <span>{t('load more')}</span>
                        <svg className='mx-2' width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.99939 0.5C8.28118 0.5 8.55143 0.611942 8.75069 0.811199C8.94995 1.01046 9.06189 1.28071 9.06189 1.5625L9.06189 13.8726L13.6221 9.31025C13.7209 9.21146 13.8382 9.1331 13.9673 9.07964C14.0963 9.02617 14.2347 8.99866 14.3744 8.99866C14.5141 8.99866 14.6524 9.02617 14.7815 9.07964C14.9106 9.1331 15.0279 9.21146 15.1266 9.31025C15.2254 9.40904 15.3038 9.52631 15.3573 9.65539C15.4107 9.78446 15.4382 9.92279 15.4382 10.0625C15.4382 10.2022 15.4107 10.3405 15.3573 10.4696C15.3038 10.5987 15.2254 10.716 15.1266 10.8148L8.75164 17.1898C8.65294 17.2887 8.53569 17.3672 8.40661 17.4208C8.27753 17.4743 8.13914 17.5019 7.99939 17.5019C7.85963 17.5019 7.72125 17.4743 7.59217 17.4208C7.46308 17.3672 7.34584 17.2887 7.24714 17.1898L0.872139 10.8148C0.773352 10.716 0.69499 10.5987 0.641527 10.4696C0.588064 10.3405 0.560547 10.2022 0.560547 10.0625C0.560547 9.92279 0.588064 9.78446 0.641527 9.65539C0.69499 9.52631 0.773352 9.40904 0.872139 9.31025C1.07165 9.11074 1.34224 8.99866 1.62439 8.99866C1.7641 8.99866 1.90243 9.02617 2.0315 9.07964C2.16058 9.1331 2.27785 9.21146 2.37664 9.31025L6.93689 13.8726L6.93689 1.5625C6.93689 1.28071 7.04883 1.01046 7.24809 0.811199C7.44735 0.611942 7.7176 0.5 7.99939 0.5Z" fill="white" />
                        </svg>
                    </button>
                </div>
                : ''}
            </section>
            <img src={fullEllipse} className='d-none d-lg-block fullEllipse' alt='' />
            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content casestudyModal">
                        <div className="modal-body">
                            <CaseStudy />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}