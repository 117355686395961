import './blogs.css';
// import blog from '../../images/blog.png';
import blog from '../../images/blogLarge.png';

export default function BlogDetails() {
    return (
        <div className='mainWrapper aboutusWrapper'>
            <section className='blogTitle'>
                <h2>Find out about the latest trends in design</h2>
                <div className='row blogdata'>
                    <time className='col-4'>December 29, 2021</time> 
                    <span className='col-3'>.Admin</span>
                    <div className='col-4 row justify-content-between'>
                        <span className='col'>
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.1667 0H1.83333C1.57917 0 1.33541 0.105357 1.15569 0.292893C0.975967 0.48043 0.875 0.734784 0.875 1V17C0.875 17.2652 0.975967 17.5196 1.15569 17.7071C1.33541 17.8946 1.57917 18 1.83333 18H10.0894V11.04H7.84879V8.315H10.0894V6.315C10.0894 3.99 11.4502 2.723 13.4435 2.723C14.1134 2.721 14.7842 2.757 15.4512 2.83V5.25H14.076C12.995 5.25 12.7842 5.788 12.7842 6.575V8.31H15.3688L15.0334 11.035H12.7832V18H17.1667C17.4208 18 17.6646 17.8946 17.8443 17.7071C18.024 17.5196 18.125 17.2652 18.125 17V1C18.125 0.734784 18.024 0.48043 17.8443 0.292893C17.6646 0.105357 17.4208 0 17.1667 0Z" fill="#A58C3B" />
                            </svg>
                        </span>
                        <span className='col'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0001 6.87579C8.2798 6.87579 6.8759 8.2797 6.8759 10C6.8759 11.7203 8.2798 13.1242 10.0001 13.1242C11.7204 13.1242 13.1243 11.7203 13.1243 10C13.1243 8.2797 11.7204 6.87579 10.0001 6.87579ZM19.3704 10C19.3704 8.70626 19.3822 7.42423 19.3095 6.13282C19.2368 4.63282 18.8947 3.30157 17.7978 2.2047C16.6986 1.10548 15.3696 0.765638 13.8696 0.692982C12.5759 0.620326 11.2939 0.632045 10.0025 0.632045C8.70871 0.632045 7.42668 0.620326 6.13527 0.692982C4.63527 0.765638 3.30402 1.10783 2.20714 2.2047C1.10792 3.30392 0.76808 4.63282 0.695424 6.13282C0.622767 7.42657 0.634486 8.7086 0.634486 10C0.634486 11.2914 0.622767 12.5758 0.695424 13.8672C0.76808 15.3672 1.11027 16.6984 2.20714 17.7953C3.30636 18.8945 4.63527 19.2344 6.13527 19.307C7.42902 19.3797 8.71105 19.368 10.0025 19.368C11.2962 19.368 12.5782 19.3797 13.8696 19.307C15.3696 19.2344 16.7009 18.8922 17.7978 17.7953C18.897 16.6961 19.2368 15.3672 19.3095 13.8672C19.3845 12.5758 19.3704 11.2938 19.3704 10ZM10.0001 14.807C7.33996 14.807 5.19308 12.6602 5.19308 10C5.19308 7.33986 7.33996 5.19298 10.0001 5.19298C12.6603 5.19298 14.8071 7.33986 14.8071 10C14.8071 12.6602 12.6603 14.807 10.0001 14.807ZM15.004 6.11876C14.3829 6.11876 13.8814 5.6172 13.8814 4.99611C13.8814 4.37501 14.3829 3.87345 15.004 3.87345C15.6251 3.87345 16.1267 4.37501 16.1267 4.99611C16.1269 5.14359 16.098 5.28966 16.0416 5.42595C15.9852 5.56224 15.9026 5.68607 15.7983 5.79036C15.694 5.89464 15.5702 5.97733 15.4339 6.03368C15.2976 6.09003 15.1515 6.11895 15.004 6.11876Z" fill="#A58C3B" />
                            </svg>
                        </span>
                        <span className='col'>
                            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.6435 2.93708C22.8085 3.30708 21.9115 3.55708 20.9685 3.67008C21.9415 3.08787 22.6694 2.17154 23.0165 1.09208C22.1023 1.63507 21.1018 2.01727 20.0585 2.22208C19.3569 1.47294 18.4276 0.976403 17.4148 0.809551C16.4021 0.642699 15.3626 0.814868 14.4577 1.29933C13.5529 1.78379 12.8333 2.55344 12.4107 3.48878C11.988 4.42412 11.886 5.47283 12.1205 6.47208C10.2682 6.37907 8.45613 5.89763 6.80192 5.05898C5.14772 4.22034 3.68834 3.04324 2.5185 1.60408C2.1185 2.29408 1.8885 3.09408 1.8885 3.94608C1.88805 4.71307 2.07693 5.46832 2.43838 6.14481C2.79982 6.8213 3.32266 7.39812 3.9605 7.82408C3.22078 7.80054 2.49737 7.60066 1.8505 7.24108V7.30108C1.85043 8.37682 2.22253 9.41945 2.90368 10.2521C3.58483 11.0847 4.53307 11.656 5.5875 11.8691C4.90128 12.0548 4.18184 12.0821 3.4835 11.9491C3.781 12.8747 4.3605 13.6841 5.14087 14.264C5.92124 14.8439 6.86342 15.1653 7.8355 15.1831C6.18533 16.4785 4.14739 17.1812 2.0495 17.1781C1.67788 17.1782 1.30658 17.1565 0.9375 17.1131C3.06698 18.4823 5.54584 19.2089 8.0775 19.2061C16.6475 19.2061 21.3325 12.1081 21.3325 5.95208C21.3325 5.75208 21.3275 5.55008 21.3185 5.35008C22.2298 4.69105 23.0164 3.87497 23.6415 2.94008L23.6435 2.93708Z" fill="#A58C3B" />
                            </svg>
                        </span>
                    </div>
                </div>
            </section>
            <article className='row articleSection'>
                <div className='col-12 col-lg-6 '>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi est, phasellus tincidunt amet tellus a tellus ut. Dignissim tincidunt dictum lectus risus, id interdum auctor dignissim nulla.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi est, phasellus tincidunt amet tellus a tellus ut. Dignissim tincidunt dictum lectus risus, id interdum auctor dignissim nulla.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi est, phasellus tincidunt amet tellus a tellus ut. Dignissim tincidunt dictum lectus risus, id interdum auctor dignissim nulla.
                    </p>
                </div>
             <div className='col-12 col-lg-6'>
                    <img src={blog} className='blogmainImg mb-5' />
             </div>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam nec auctor tincidunt elementum. Sed libero amet eget augue molestie dignissim platea facilisi ut. Neque quam nisl justo, tempor sit lorem. Bibendum justo turpis ultricies dignissim molestie magna dolor. Mi eget libero, aliquam enim. Pretium sit ipsum faucibus egestas blandit elit, risus. Egestas semper arcu volutpat massa ornare risus etiam mi erat. Consequat ipsum sed viverra at etiam mus sed egestas sed.
                </p>
                <p>
                    Tincidunt a vitae egestas neque, lectus. Vulputate bibendum nec, vestibulum proin gravida nibh praesent mauris diam. Posuere penatibus eget volutpat varius arcu. Ut felis fames egestas diam augue ut. Etiam id vitae sed mauris. Diam ut sit donec dui quam. Urna donec blandit viverra tortor sapien etiam eget. Habitant orci velit facilisi egestas. Scelerisque vulputate fermentum ultrices pulvinar non sed.
                </p>

            </article>
            <div className='articlePagination'>
                <button className='defaultBtn firstSlideBtn'>
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 7.99988C17.5 8.28167 17.3881 8.55192 17.1888 8.75118C16.9895 8.95044 16.7193 9.06238 16.4375 9.06238L4.12737 9.06238L8.68975 13.6226C8.78854 13.7214 8.8669 13.8387 8.92036 13.9678C8.97383 14.0968 9.00134 14.2352 9.00134 14.3749C9.00134 14.5146 8.97383 14.6529 8.92036 14.782C8.8669 14.9111 8.78854 15.0283 8.68975 15.1271C8.59096 15.2259 8.47369 15.3043 8.34462 15.3577C8.21554 15.4112 8.07721 15.4387 7.9375 15.4387C7.79779 15.4387 7.65946 15.4112 7.53039 15.3577C7.40131 15.3043 7.28404 15.2259 7.18525 15.1271L0.810249 8.75213C0.711304 8.65343 0.632799 8.53618 0.579237 8.4071C0.525673 8.27802 0.4981 8.13963 0.4981 7.99988C0.4981 7.86012 0.525673 7.72174 0.579237 7.59266C0.632799 7.46357 0.711304 7.34632 0.810249 7.24763L7.18525 0.872628C7.28404 0.773841 7.40131 0.695479 7.53039 0.642016C7.65946 0.588552 7.79779 0.561035 7.9375 0.561035C8.07721 0.561035 8.21554 0.588552 8.34461 0.642016C8.47369 0.695479 8.59096 0.773841 8.68975 0.872628C8.88926 1.07214 9.00134 1.34273 9.00134 1.62488C9.00134 1.76458 8.97383 1.90292 8.92036 2.03199C8.8669 2.16106 8.78854 2.27834 8.68975 2.37713L4.12737 6.93738L16.4375 6.93738C16.7193 6.93738 16.9895 7.04932 17.1888 7.24858C17.3881 7.44783 17.5 7.71809 17.5 7.99988Z" fill="white" />
                    </svg>
                    <span className='ms-3'>Previous</span>
                </button>
                <button className='defaultBtn firstSlideBtn'>
                    <span>Next</span>
                    <svg className='ms-3' width="15" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.5 8.00012C0.5 7.71833 0.611942 7.44808 0.811199 7.24882C1.01046 7.04956 1.28071 6.93762 1.5625 6.93762L13.8726 6.93762L9.31025 2.37737C9.21146 2.27859 9.1331 2.16131 9.07964 2.03224C9.02617 1.90317 8.99866 1.76483 8.99866 1.62512C8.99866 1.48542 9.02617 1.34708 9.07964 1.21801C9.1331 1.08894 9.21146 0.971658 9.31025 0.872872C9.40904 0.774085 9.52631 0.695722 9.65538 0.642259C9.78446 0.588796 9.92279 0.56128 10.0625 0.56128C10.2022 0.56128 10.3405 0.588796 10.4696 0.642259C10.5987 0.695722 10.716 0.774085 10.8148 0.872872L17.1898 7.24787C17.2887 7.34657 17.3672 7.46382 17.4208 7.5929C17.4743 7.72198 17.5019 7.86037 17.5019 8.00012C17.5019 8.13988 17.4743 8.27826 17.4208 8.40734C17.3672 8.53643 17.2887 8.65367 17.1898 8.75237L10.8148 15.1274C10.716 15.2262 10.5987 15.3045 10.4696 15.358C10.3405 15.4114 10.2022 15.439 10.0625 15.439C9.92279 15.439 9.78446 15.4114 9.65539 15.358C9.52631 15.3045 9.40904 15.2262 9.31025 15.1274C9.11074 14.9279 8.99866 14.6573 8.99866 14.3751C8.99866 14.2354 9.02617 14.0971 9.07964 13.968C9.1331 13.8389 9.21146 13.7217 9.31025 13.6229L13.8726 9.06262L1.5625 9.06262C1.28071 9.06262 1.01046 8.95068 0.811199 8.75142C0.611942 8.55217 0.5 8.28191 0.5 8.00012Z" fill="white" />
                    </svg>
                </button>
            </div>
        </div>
    )
}