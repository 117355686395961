import React, { useState, useEffect } from 'react';
import { ThemeContext, themes } from './theme.jsx';

export default function ThemeContextWrapper(props) {
  const [theme, setTheme] = useState(localStorage.getItem('theme'));

  function changeTheme(theme) {
    setTheme(theme);
  } 
  var r = document.querySelector(':root');
  useEffect(()=>{
    let themeName = localStorage.getItem('theme');
    if(themeName === 'light'){
      setTheme(themes.light)
    }else if(themeName === 'dark'){
      setTheme(themes.dark)
    }
  },[theme])

  useEffect(() => {
    switch (theme) {
      case themes.light:
        document.body.classList.add('white-content');
        document.body.classList.remove('dark-content');
        r.style.setProperty('--mainText', '#3f3f3f');
        r.style.setProperty('--background', '#ffffff');
        break;
      case themes.dark:
      default:
        document.body.classList.remove('white-content');
        document.body.classList.add('dark-content');
        r.style.setProperty('--mainText', '#ffffff');
        r.style.setProperty('--background', '#161616');
        break;
    }
    //eslint-disable-next-line
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}