import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home/home';
import Navbar from './Components/navbar/Navbar';
import Footer from './Components/footer/footer';
import Notfound from './Components/not found/notfound';
import Contactus from './Components/contactUs/contactus';
import Services from './Components/services/services';
import Projects from './Components/projects/projects';
import AboutUs from './Components/About/aboutUs';
import Allblogs from './Components/Blogs/allBlogs';
import BlogDetails from './Components/Blogs/blog-details';
import NavbarMobile from './Components/navbar/navbarMobile';
import '../src/arabicStylels.scss'
import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import staticwhatsapp from './images/whatsapp.svg';
import { getSocials } from './api/pages';
import PrivacyPolicy from './Components/PrivacyPolicy/privacyPolicy';
import Offers from './Components/Offers/Offers';

function AppRouter() {
  const [socials, setsocials] = useState();
  let { i18n } = useTranslation()
  useEffect(() => {
    getSocials().then((res) => {
      setsocials(res.data.data)
    })
    //eslint-disable-next-line
  }, []);

  
  return (
    <div className={`${i18n.language === 'en' ? 'englishWrapper' : 'arabicWrapper'} `}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dubisign</title>
      </Helmet>
      <BrowserRouter>
        <Navbar />
        <NavbarMobile />
        <Routes>
          {/* <ReactCSSTransitionGroup >/ */}
          <Route path=":lang">
            <Route path="" element={<Home />} />
            <Route path="contact-us" element={<Contactus />} />
            <Route path="services" element={<Services />} />
            <Route path="offers" element={<Offers />} />
            <Route path="our-projects" element={<Projects />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="all-blogs" element={<Allblogs />} />
            <Route path="blog-details" element={<BlogDetails />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path='*' element={<Notfound />} />
          </Route>
        </Routes>
        {socials && socials.map(soc => {
          if (soc.id === 17)
            return (
              <a target="_blank" className='staticWhatsapp' key={soc?.id} href={soc?.link} rel="noreferrer">
                <img src={staticwhatsapp} width={70} height={70} alt='' />
              </a>
            )
            return true;
        }
        )}
     
        <Footer socials={socials} />
      </BrowserRouter>
    </div>

  );
}

export default AppRouter;
