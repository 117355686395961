
import { api_url } from "../config/config";
import { https } from "./../https";

// get projects
export async function getProjects(per_page){
    return await https.get(`${api_url}/projects?per_page=${per_page}`);
}
// get service project
export async function getServiceProject(per_page,serviceId){
    return await https.get(`${api_url}/projects?services[0]=${serviceId}&per_page=${per_page}`);
}