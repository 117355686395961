
import { api_url } from "../config/config";
import { https } from "./../https";


export async function getAbout() {
    return await https.get(`${api_url}/settings/about`);
  }
//get services headers
export async function getServicesHeaders() {
  return await https.get(`${api_url}/settings/services`);
}
//get contact us
export async function getContactUs() {
  return await https.get(`${api_url}/settings/contact-us`);
}
//send contact us
export async function sendContactUs(data) {
  return await https.post(`${api_url}/contact-us`,data);
}
export async function getTeam() {
  return await https.get(`${api_url}/teams`);
}
export async function getSocials() {
  return await https.get(`${api_url}/socials`);
}
//privacy policy
export async function getPrivacyPolicy() {
  return await https.get(`${api_url}/settings/privacy-policy`);
}