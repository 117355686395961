import { NavLink } from 'react-router-dom';
import logo from '../../images/dubiLogo.png';
import { useState, useEffect, useContext } from 'react';
import { ThemeContext, themes } from '../contexts/theme';
import englishImg from '../../images/english.webp';
import arabicImg from '../../images/uae.svg';
import { useTranslation } from 'react-i18next';
// import { basename } from '../../config/config';
// import closeBtn from '../../images/close.svg'
import { getSocials } from '../../api/pages';

export default function NavbarMobile() {
    const [darkMode, setDarkMode] = useState();
    const { theme, changeTheme } = useContext(ThemeContext);
    const [socials, setsocials] = useState();
    const [Langs] = useState([
        { slug: 'en', img: englishImg, name: 'English' },
        { slug: 'ar', img: arabicImg, name: 'Arabic' }
    ]);
    let { t, i18n } = useTranslation()
    const basename = i18n.language;

    useEffect(() => {
        if (theme === 'light') {
            setDarkMode(false)
        } else {
            setDarkMode(true)
        }
        getSocials().then((res) => {
            setsocials(res.data.data)
        })
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (darkMode) {
            changeTheme(themes.dark);
            localStorage.setItem('theme', 'dark')
            document.getElementById("myCheck").checked = true;
        } else {
            changeTheme(themes.light);
            localStorage.setItem('theme', 'light')
        }
        // eslint-disable-next-line 
    }, [darkMode]);

    return (
        <>
            <nav className="mainWrapper navbar  w-100 d-lg-none">
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobileNavbar" aria-controls="offcanvasNavbar">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33.4167 18.333H6.58333C5.70888 18.333 5 19.0419 5 19.9163V20.083C5 20.9575 5.70888 21.6663 6.58333 21.6663H33.4167C34.2911 21.6663 35 20.9575 35 20.083V19.9163C35 19.0419 34.2911 18.333 33.4167 18.333Z" fill="#A58C3B" />
                        <path d="M33.4167 26.667H6.58333C5.70888 26.667 5 27.3759 5 28.2503V28.417C5 29.2914 5.70888 30.0003 6.58333 30.0003H33.4167C34.2911 30.0003 35 29.2914 35 28.417V28.2503C35 27.3759 34.2911 26.667 33.4167 26.667Z" fill="#A58C3B" />
                        <path d="M33.4167 10H6.58333C5.70888 10 5 10.7089 5 11.5833V11.75C5 12.6245 5.70888 13.3333 6.58333 13.3333H33.4167C34.2911 13.3333 35 12.6245 35 11.75V11.5833C35 10.7089 34.2911 10 33.4167 10Z" fill="#A58C3B" />
                    </svg>
                </button>
                <NavLink className={`nav-links`} to={`${basename}`}>
                    <img src={logo} alt='' />
                </NavLink>
                <div></div>
            </nav>
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="mobileNavbar" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header px-4">
                    <NavLink className={`nav-links`} to={`${basename}`}>
                        <img src={logo} alt='logo' />
                    </NavLink>
                    <button type="button" className="btnClose text-reset" data-bs-dismiss="offcanvas" aria-label="Close">
                        {/* <img src={closeBtn} alt='close' /> */}
                        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.3708 12.4137C15.5894 11.6327 15.5892 10.3661 16.3704 9.58494L22.3693 3.58605C22.735 3.18745 22.9326 2.66301 22.9209 2.1222C22.9092 1.5814 22.689 1.06601 22.3064 0.683639C21.9238 0.301263 21.4082 0.0814458 20.8674 0.0700709C20.3266 0.058696 19.8023 0.256642 19.4039 0.622591L19.4059 0.620668L13.4066 6.61992C12.6256 7.40097 11.3592 7.40097 10.5782 6.61992L4.57893 0.620668C4.18033 0.254978 3.65589 0.0573713 3.11508 0.069097C2.57427 0.0808228 2.05889 0.300975 1.67652 0.683598C1.29414 1.06622 1.07432 1.58175 1.06295 2.12256C1.05157 2.66338 1.24952 3.18769 1.61547 3.58605L1.61355 3.58413L7.6128 9.58338C8.39385 10.3644 8.39384 11.6308 7.6128 12.4118L1.61355 18.4111C1.40496 18.6024 1.23726 18.834 1.12053 19.0919C1.00381 19.3498 0.940467 19.6286 0.934331 19.9116C0.928195 20.1946 0.979388 20.4759 1.08483 20.7386C1.19027 21.0013 1.34778 21.2399 1.54787 21.4402C1.74796 21.6404 1.9865 21.798 2.24913 21.9036C2.51175 22.0093 2.79303 22.0606 3.07603 22.0547C3.35904 22.0487 3.63791 21.9856 3.89586 21.869C4.15381 21.7525 4.38551 21.5849 4.57701 21.3764L4.57893 21.3745L10.5782 15.3753C11.3592 14.5942 12.6256 14.5942 13.4066 15.3753L19.4059 21.3745C19.5972 21.5831 19.8288 21.7508 20.0867 21.8675C20.3446 21.9843 20.6234 22.0476 20.9064 22.0537C21.1894 22.0599 21.4707 22.0087 21.7334 21.9032C21.9961 21.7978 22.2347 21.6403 22.435 21.4402C22.6352 21.2401 22.7928 21.0016 22.8984 20.7389C23.0041 20.4763 23.0554 20.195 23.0495 19.912C23.0435 19.629 22.9804 19.3502 22.8638 19.0922C22.7473 18.8343 22.5797 18.6026 22.3712 18.4111L22.3693 18.4091L16.3708 12.4137Z" fill="white" />
                        </svg>
                    </button>
                </div>
                <div className="offcanvas-body d-flex flex-column px-4">
                    <ul className='navbarMobile-list mb-auto'>
                        <li data-bs-dismiss="offcanvas">
                            <NavLink className={`mobile_links`} to={`${basename}`} end={true}>{t('Home')}</NavLink>
                        </li>
                        <li data-bs-dismiss="offcanvas">
                            <NavLink className={`mobile_links`} to={`${basename}/about-us`}>{t('About')}</NavLink>
                        </li>
                        <li data-bs-dismiss="offcanvas">
                            <NavLink className={`mobile_links`} to={`${basename}/services`}>{t('Services')}</NavLink>
                        </li>
                        <li data-bs-dismiss="offcanvas">
                            <NavLink className={`mobile_links`} to={`${basename}/offers`}>{t('Offers')}</NavLink>
                        </li>
                        <li data-bs-dismiss="offcanvas">
                            <NavLink className={`mobile_links`} to={`${basename}/our-projects`}>{t('Projects')}</NavLink>
                        </li>
                        <li data-bs-dismiss="offcanvas">
                            <NavLink className={`mobile_links`} to={`${basename}/contact-us`}>{t('Contact')}</NavLink>
                        </li>
                    </ul>
                    <div className='themeIcons'>
                        <div className='d-flex justify-content-between mb-5 align-items-center '>
                            <span className='socialIcons'>
                                <label className="switch">
                                    <input className='themeToggler' id='myCheck' type="checkbox" onClick={() => {
                                        setDarkMode(!darkMode)
                                    }} />
                                    <span className="slider round"></span>
                                </label>
                            </span>
                            <span className='socialIcons footerFont'>
                                {/* eslint-disable-next-line */}
                                {Langs && Langs.map((lang, index) => {
                                    if (i18n.language !== lang.slug) {
                                        return (
                                            <div key={index} className='langToggler' onClick={() => i18n.changeLanguage(lang.slug)}>
                                                <img className='flagImg' src={lang.img} alt='' />
                                                <span className='mx-2'>{lang.name}</span>
                                            </div>
                                        )
                                    }
                                })}
                            </span>
                        </div>
                        <div className='navbarFooter'>
                            <div className='d-flex justify-content-around socailNavbar'>
                                {socials && socials.map(soc => (
                                    <a target="_blank" className='socialIcons' key={soc?.id} href={soc?.link} rel="noreferrer">
                                        <img alt="link" src={soc?.image?.url} />
                                    </a>
                                ))}
                            </div>
                            <div className='row mt-4'>
                                <span className='footerFont text-center'>Dubisign. All Rights Reserved, 2021.</span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
