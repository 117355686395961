import post from '../../images/post.png';
import './blogs.css';
import React, { useEffect, useState } from "react";
import { WOW } from "wowjs";
import moment from 'moment';
import axios from 'axios';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

export default function Allblogs() {
    const [categories, setCategories] = useState([]);
    const [blogs, setBlogs] = useState([]);
    let { t } = useTranslation()

    useEffect(() => {
        const wow = new WOW({live: false});
        wow.init();
    }, [])
    // get all categories
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/api/blogs`).then(
            (res) => {
                // console.log(res.data.data);
                let category = res.data.data.categories;
                setCategories(category);
            }
        )
    }, [])
    // get all blogs
    function getAllblogs(){
        axios.get(`${process.env.REACT_APP_API}/api/blogs`).then(
            (res) => {
                console.log(res.data.data.blogs);
                let blog = res.data.data.blogs;
                setBlogs(blog);
            }
        )
    }
    useEffect(() => {
      getAllblogs();
    }, [])
    // get blogs by year
    function getBlogsbyYear(){
        axios.get(`${process.env.REACT_APP_API}/api/blogs/year/20212`).then(
            (res) => {
                console.log(res.data.data);
                let blog = res.data.data;
                setBlogs(blog);
            }
        )
    };
    return (
        <div className='mainWrapper aboutusWrapper position-relative'>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Dubisign | {t('blogs')}</title>
            </Helmet>
            <h1 className='homeHeadline'>{t('blogs')}</h1>
            <div className='d-flex justify-content-end w-100'>
                <div className='dropdown categoriesBtnWrap'>
                    <button className='defaultBtn firstSlideBtn d-block d-lg-none categoriesBtn' data-bs-toggle="dropdown">
                        <span>Categories</span>
                        <svg className='ms-3' width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.13757 0.0228271C5.29719 0.0228271 5.45027 0.0852503 5.56314 0.196364C5.67601 0.307478 5.73942 0.45818 5.73942 0.615318L5.73942 7.47992L8.32258 4.93576C8.37854 4.88068 8.44497 4.83698 8.51808 4.80716C8.5912 4.77735 8.66956 4.76201 8.74869 4.76201C8.82783 4.76201 8.90619 4.77735 8.97931 4.80716C9.05242 4.83698 9.11885 4.88068 9.17481 4.93576C9.23077 4.99085 9.27515 5.05625 9.30544 5.12822C9.33572 5.2002 9.35131 5.27734 9.35131 5.35525C9.35131 5.43315 9.33572 5.51029 9.30544 5.58227C9.27515 5.65425 9.23077 5.71964 9.17481 5.77473L5.56368 9.32968C5.50777 9.38485 5.44136 9.42863 5.36824 9.4585C5.29512 9.48837 5.21673 9.50374 5.13757 9.50374C5.0584 9.50374 4.98002 9.48837 4.9069 9.4585C4.83378 9.42863 4.76736 9.38485 4.71146 9.32968L1.10033 5.77473C1.04437 5.71964 0.999984 5.65425 0.969699 5.58227C0.939415 5.51029 0.923828 5.43315 0.923828 5.35525C0.923828 5.27734 0.939415 5.2002 0.969699 5.12822C0.999984 5.05625 1.04437 4.99085 1.10033 4.93576C1.21334 4.82451 1.36662 4.76201 1.52644 4.76201C1.60558 4.76201 1.68394 4.77735 1.75705 4.80716C1.83017 4.83698 1.8966 4.88068 1.95256 4.93576L4.53571 7.47992L4.53571 0.615318C4.53571 0.45818 4.59912 0.307478 4.71199 0.196364C4.82486 0.0852503 4.97795 0.0228271 5.13757 0.0228271Z" fill="white" />
                        </svg>
                    </button>
                    <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuLink">
                    {categories.map((category, i) => {
                            return (
                                <li  key={category.slug}><a className="dropdown-item" href="#">{category.name_en}</a></li>
                            )
                        })}
                  
                    </ul>
                </div>
            </div>
            <div className='row'>
                <section className='col-12 col-lg-8  wow animate__backInUp' data-wow-duration='1s'>
                    {blogs && blogs.length ? blogs.map((blog)=>{
                        return(

                    <article key={blog.slug} className='row blog justify-content-between align-items-center blog-card' data-bs-toggle="collapse" data-bs-target="#firstCollapse">
                        <div className='col-12 col-lg-2 px-0'>
                            <img src={`${process.env.REACT_APP_API}/${blog.thumbnail}`} className="blogImg" />
                        </div>
                        <div className=' col-12 col-lg-8'>
                            <div className="h-100 d-flex flex-column align-items-start">
                                <div className="mb-4 blogName">{blog.title}</div>
                                <div className='d-flex justify-content-between blogDate' >
                                    <time>{blog.created_at && moment(blog.created_at).format(`DD MMM, YYYY `)}</time> <span>-{blog.admin && blog.admin.username}</span>
                                </div>
                                <div className='collapse' id='firstCollapse'>
                                    <p className='blogSample d-block d-lg-none'>
                                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro debitis dolorum delectus officiis. Alias sit blanditiis suscipit. Animi nulla accusamus ipsam odit mollitia nesciunt ad ex natus explicabo. Asperiores, atque.
                                    </p>
                                    <div className='d-flex justify-content-center w-100' onClick={() => { console.log('hello') }}>
                                        <button className='defaultBtn firstSlideBtn d-block d-lg-none '>
                                            <span>Read More</span>
                                            <svg className='ms-3' width="15" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0.5 8.00012C0.5 7.71833 0.611942 7.44808 0.811199 7.24882C1.01046 7.04956 1.28071 6.93762 1.5625 6.93762L13.8726 6.93762L9.31025 2.37737C9.21146 2.27859 9.1331 2.16131 9.07964 2.03224C9.02617 1.90317 8.99866 1.76483 8.99866 1.62512C8.99866 1.48542 9.02617 1.34708 9.07964 1.21801C9.1331 1.08894 9.21146 0.971658 9.31025 0.872872C9.40904 0.774085 9.52631 0.695722 9.65538 0.642259C9.78446 0.588796 9.92279 0.56128 10.0625 0.56128C10.2022 0.56128 10.3405 0.588796 10.4696 0.642259C10.5987 0.695722 10.716 0.774085 10.8148 0.872872L17.1898 7.24787C17.2887 7.34657 17.3672 7.46382 17.4208 7.5929C17.4743 7.72198 17.5019 7.86037 17.5019 8.00012C17.5019 8.13988 17.4743 8.27826 17.4208 8.40734C17.3672 8.53643 17.2887 8.65367 17.1898 8.75237L10.8148 15.1274C10.716 15.2262 10.5987 15.3045 10.4696 15.358C10.3405 15.4114 10.2022 15.439 10.0625 15.439C9.92279 15.439 9.78446 15.4114 9.65539 15.358C9.52631 15.3045 9.40904 15.2262 9.31025 15.1274C9.11074 14.9279 8.99866 14.6573 8.99866 14.3751C8.99866 14.2354 9.02617 14.0971 9.07964 13.968C9.1331 13.8389 9.21146 13.7217 9.31025 13.6229L13.8726 9.06262L1.5625 9.06262C1.28071 9.06262 1.01046 8.95068 0.811199 8.75142C0.611942 8.55217 0.5 8.28191 0.5 8.00012Z" fill="white" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-1 d-none d-lg-block'>
                            <svg width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.773437 13.5476C0.773437 13.0579 0.967995 12.5882 1.31431 12.2418C1.66063 11.8955 2.13033 11.701 2.62009 11.701L24.0155 11.701L16.0859 3.77512C15.9142 3.60343 15.778 3.3996 15.6851 3.17527C15.5922 2.95094 15.5444 2.7105 15.5444 2.46769C15.5444 2.22488 15.5922 1.98444 15.6851 1.76011C15.778 1.53578 15.9142 1.33195 16.0859 1.16026C16.2576 0.988563 16.4614 0.852367 16.6858 0.759446C16.9101 0.666526 17.1505 0.618701 17.3933 0.618701C17.6362 0.618701 17.8766 0.666526 18.1009 0.759446C18.3253 0.852367 18.5291 0.988563 18.7008 1.16026L29.7807 12.2402C29.9527 12.4117 30.0891 12.6155 30.1822 12.8399C30.2753 13.0642 30.3232 13.3047 30.3232 13.5476C30.3232 13.7905 30.2753 14.031 30.1822 14.2554C30.0891 14.4797 29.9527 14.6835 29.7807 14.8551L18.7008 25.935C18.5291 26.1067 18.3253 26.2429 18.1009 26.3358C17.8766 26.4287 17.6362 26.4766 17.3934 26.4766C17.1505 26.4766 16.9101 26.4287 16.6858 26.3358C16.4614 26.2429 16.2576 26.1067 16.0859 25.935C15.7392 25.5883 15.5444 25.118 15.5444 24.6276C15.5444 24.3848 15.5922 24.1443 15.6851 23.92C15.778 23.6957 15.9142 23.4918 16.0859 23.3201L24.0155 15.3943L2.62009 15.3943C2.13033 15.3943 1.66063 15.1997 1.31431 14.8534C0.967995 14.5071 0.773437 14.0374 0.773437 13.5476Z" fill="#A58C3B" />
                            </svg>
                        </div>

                    </article>
                        )
                    })
                :
                <div className='noResults'>
                   <span>No blogs found !</span> 
                     <button className='defaultBtn' onClick={getAllblogs}> show all blogs</button>
                </div>
                }
               


                </section>

                <aside className='col-12 col-lg-3 mx-auto d-none d-lg-block wow animate__backInDown' data-wow-duration='1s'>
                    <section className='tagsWrapper'>
                        {categories.map((category, i) => {
                            return (
                                <span key={category.slug} className={`badges ${i === 0 && 'active'}`}>{category.name_en}</span>
                            )
                        })}

                    </section>
                    <section className="archivesSection">
                        <h5>Archive</h5>
                        <div className='d-flex align-items-center' onClick={getBlogsbyYear}>
                            <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.517915 0.123759L3.61564 2.58804C3.89902 2.82181 3.89902 3.1822 3.61564 3.40623L0.517915 5.87051C0.224756 6.10428 -1.88243e-10 5.98739 -1.59414e-08 5.627L-2.45852e-07 0.367266C-2.62031e-07 -0.00286388 0.224755 -0.110007 0.517915 0.123759Z" fill="#A58C3B" />
                            </svg>
                            <span className='mx-2 archiveYear'>2021</span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.517915 0.123759L3.61564 2.58804C3.89902 2.82181 3.89902 3.1822 3.61564 3.40623L0.517915 5.87051C0.224756 6.10428 -1.88243e-10 5.98739 -1.59414e-08 5.627L-2.45852e-07 0.367266C-2.62031e-07 -0.00286388 0.224755 -0.110007 0.517915 0.123759Z" fill="#A58C3B" />
                            </svg>
                            <span className='mx-2 archiveYear'>2021</span>
                        </div>         <div className='d-flex align-items-center'>
                            <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.517915 0.123759L3.61564 2.58804C3.89902 2.82181 3.89902 3.1822 3.61564 3.40623L0.517915 5.87051C0.224756 6.10428 -1.88243e-10 5.98739 -1.59414e-08 5.627L-2.45852e-07 0.367266C-2.62031e-07 -0.00286388 0.224755 -0.110007 0.517915 0.123759Z" fill="#A58C3B" />
                            </svg>
                            <span className='mx-2 archiveYear'>2021</span>
                        </div>
                    </section>
                    <section className="posts">
                        <h5>Popular Posts</h5>
                        <div className='row'>
                            <div className='col-3'>
                                <img src={post} className="blogImg" />
                            </div>
                            <div className='col-9'>
                                <h6 className='postName'>Lorem Ipsum dolor sit amet</h6>
                                <div className='blogDate w-100'><time>December 29, 2021</time></div>
                            </div>
                        </div>
                    </section>


                </aside>
                <div className='d-flex justify-content-center w-100 mb-5 d-lg-none'>
                    <button className='defaultBtn firstSlideBtn d-block d-lg-none '>
                        <span>View Older</span>
                        <svg className='ms-3' width="15" height="15" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.13757 0.0228271C5.29719 0.0228271 5.45027 0.0852503 5.56314 0.196364C5.67601 0.307478 5.73942 0.45818 5.73942 0.615318L5.73942 7.47992L8.32258 4.93576C8.37854 4.88068 8.44497 4.83698 8.51808 4.80716C8.5912 4.77735 8.66956 4.76201 8.74869 4.76201C8.82783 4.76201 8.90619 4.77735 8.97931 4.80716C9.05242 4.83698 9.11885 4.88068 9.17481 4.93576C9.23077 4.99085 9.27515 5.05625 9.30544 5.12822C9.33572 5.2002 9.35131 5.27734 9.35131 5.35525C9.35131 5.43315 9.33572 5.51029 9.30544 5.58227C9.27515 5.65425 9.23077 5.71964 9.17481 5.77473L5.56368 9.32968C5.50777 9.38485 5.44136 9.42863 5.36824 9.4585C5.29512 9.48837 5.21673 9.50374 5.13757 9.50374C5.0584 9.50374 4.98002 9.48837 4.9069 9.4585C4.83378 9.42863 4.76736 9.38485 4.71146 9.32968L1.10033 5.77473C1.04437 5.71964 0.999984 5.65425 0.969699 5.58227C0.939415 5.51029 0.923828 5.43315 0.923828 5.35525C0.923828 5.27734 0.939415 5.2002 0.969699 5.12822C0.999984 5.05625 1.04437 4.99085 1.10033 4.93576C1.21334 4.82451 1.36662 4.76201 1.52644 4.76201C1.60558 4.76201 1.68394 4.77735 1.75705 4.80716C1.83017 4.83698 1.8966 4.88068 1.95256 4.93576L4.53571 7.47992L4.53571 0.615318C4.53571 0.45818 4.59912 0.307478 4.71199 0.196364C4.82486 0.0852503 4.97795 0.0228271 5.13757 0.0228271Z" fill="white" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}