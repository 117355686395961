import { filterLanguage } from "../../util/filterLanguage";

export default function ClientCard({ item ,customClass}) {
    return (
        <div className={`clientCard ${customClass} `}>
            <section className='d-flex align-items-center clientData  py-3 mx-4'>
                <img src={item?.image?.webp} className='clientImg' alt='' />
                <div className='ms-3'>
                    <h5 className='clientname'>{item && item?.locales && item?.locales.length > 0 && filterLanguage(item)[0].name}</h5>
                    <h6 className='clientpos'>{item && item?.locales && item?.locales.length > 0 && filterLanguage(item)[0].job}</h6>
                </div>
            </section>
            <section className='p-3'>
                <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0835 2.9668C9.20361 4.28516 7.75098 6.50684 6.72559 9.63184C6.4082 10.6084 6.21289 11.5728 6.13965 12.5249H6.46924C8.95947 12.5249 10.7051 13.6846 11.7061 16.0039C11.999 16.7119 12.1455 17.4932 12.1455 18.3477C12.1455 20.0078 11.6084 21.3628 10.5342 22.4126C9.45996 23.438 8.32471 23.9507 7.12842 23.9507C5.02881 23.9507 3.4541 23.3159 2.4043 22.0464C1.35449 20.7769 0.82959 19.1533 0.82959 17.1758C0.82959 13.8311 1.7207 10.584 3.50293 7.43457C5.30957 4.26074 7.20166 2.06348 9.1792 0.842773L11.0835 2.9668ZM28.6323 2.9668C26.7524 4.28516 25.2998 6.50684 24.2744 9.63184C23.957 10.6084 23.7617 11.5728 23.6885 12.5249H24.0181C26.5083 12.5249 28.2539 13.6846 29.2549 16.0039C29.5479 16.7119 29.6943 17.4932 29.6943 18.3477C29.6943 20.0078 29.1572 21.3628 28.083 22.4126C27.0088 23.438 25.8735 23.9507 24.6772 23.9507C22.5776 23.9507 21.0029 23.3159 19.9531 22.0464C18.9033 20.7769 18.3784 19.1533 18.3784 17.1758C18.3784 13.8311 19.2695 10.584 21.0518 7.43457C22.8584 4.26074 24.7505 2.06348 26.728 0.842773L28.6323 2.9668Z" fill="#7C7B7B" />
                </svg>
                <p className='clientPar'>{item && item?.locales && item?.locales.length > 0 && filterLanguage(item)[0].description}
                </p>
            </section>
        </div>
    )
}