import React from 'react'
import check from '../../images/icons/offerPoints.svg'
export default function OfferCard({ t, offer }) {
    return (
        <div className='offerCard h-100'>
            <img className='packageImg' src={offer?.image} alt='' />
            <div className='packageName'>
                <span className='line'></span>
                <span className='name'>{offer?.name}</span>
                <span className='line'></span>

            </div>
            <div className='offerData'>
                {/* <p>{offer?.desc}</p> */}
                <div>
                    {offer?.features && offer?.features.map((point) => {
                        return (
                            <div className='offerPoint'>
                                <img className='offerCheck' src={check} alt='' />
                                <span>{point} </span>
                            </div>
                        )
                    })}
                </div>
                <a target="_blank" className='offerBtn defaultBtn' href={`https://wa.me/966549200239?text=I'm%20interested%20in%20your%20package%20${offer?.name}`} rel="noreferrer">
                    {t('Buy now')}
                </a>
            </div>
        </div>
    )
}
