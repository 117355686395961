
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import logo from '../../images/dubiLogo.png';
import './navbar.css';
export default function Navbar() {
    let { t, i18n } = useTranslation()
    const basename = i18n.language;

    return (
        <nav className="mainWrapper navbar d-none d-lg-flex">
            <NavLink className={`nav-links`} to={`${basename}`}>
                <img src={logo} alt='logo' />
            </NavLink>
            <div>
                <ul className="navbar-list">
                    <li>
                        <NavLink className={`nav-links`} to={`${basename}`} end={true}>{t('Home')}</NavLink>
                    </li>
                    <li>
                        <NavLink className={`nav-links`} to={`${basename}/about-us`}>{t('About')}</NavLink>
                    </li>
                    {/* <li>
                        <NavLink className={`nav-links`} to={`${basename}/all-blogs`}>Blog</NavLink>
                    </li> */}
                    <li>
                        <NavLink className={`nav-links`} to={`${basename}/services`}>{t('Services')}</NavLink>
                    </li>
                    <li>
                        <NavLink className={`nav-links`} to={`${basename}/offers`}>{t('Offers')}</NavLink>
                    </li>
                    <li>
                        <NavLink className={`nav-links`} to={`${basename}/our-projects`}>{t('Projects')}</NavLink>
                    </li>
                    <li>
                        <NavLink className={`nav-links mx-0`} to={`${basename}/contact-us`}>{t('Contact')}</NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    )
}
