import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRouter from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css' ;
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'animate.css';
import './i18n';
import ThemeContextWrapper from './Components/contexts/themecontextWrapper.jsx';


ReactDOM.render(
  <ThemeContextWrapper>
      <AppRouter />
  </ThemeContextWrapper>,
  document.getElementById('root')
);

reportWebVitals();
