import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getContactUs, sendContactUs } from '../../api/pages';
// import { filterHeaders } from '../../util/filterLanguage';
import { recaptcha_key, recaptcha_secretKey } from './../../config/config';
import Map from '../map/map';
import './contactus.css';
import { Helmet } from "react-helmet";
import Recaptcha from 'react-google-recaptcha';
import ellipse from '../../images/Ellipse13.png';

export default function Contactus() {
    // let [contactHeaders, setContactHeaders] = useState()
    let [contactData, setContactData] = useState()
    let [contact, setcontact] = useState()
    let [errors, setErrors] = useState()
    let { t, i18n } = useTranslation()
    let refRecaptcha = useRef();
    let contactFormRef = useRef()
    useEffect(() => {
        getContactUs().then((res) => {
            // setContactHeaders(res.data.data.contact_us);
            setContactData(res.data.data);
        })
    }, [])
    function contactInputsHandler(e) {
        setcontact({
            ...contact,
            [e.target.name]: e.target.value
        })
        setErrors({
            ...errors,
            [e.target.name]: ''
        })
    }
    async function contactSubmitHandler(e) {
        e.preventDefault()

        let token = await refRecaptcha.current.executeAsync()
        let data = { ...contact }
        data['captcha'] = token
        sendContactUs(data).then((res) => {
            refRecaptcha.current.reset()
            contactFormRef.current.reset()
        }).catch((err) => {
            refRecaptcha.current.reset()
            setErrors({
                ...errors,
                ...err.response.data.errors
            })
        })

    }

    return (
        <div className='mainWrapper contactUs d-flex align-items-center'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dubisign | {t('contact')}</title>
            </Helmet>
            <div className='row  w-100 justify-content-between align-items-start  m-0 '>
                <section className='col-12 col-lg-6 addresSection'>
                    <h2 className='contactHeade'>{t('How to find us')}</h2>
                    <div className='addressContainer'>
                        <div className='tabs'>
                            <div className="nav nav-tabs w-100 h-100 d-flex align-items-center" id="myTab" role="tablist">
                                <div className="navlinks active col-6 contactTabs" id="Uae-tab" data-bs-toggle="tab" data-bs-target="#UAE" type="button" role="tab" aria-controls="UAE" aria-selected="true"><span>{t('UAE')}</span></div>
                                <div className="navlinks col-6 contactTabs" id="Egypt-tab" data-bs-toggle="tab" data-bs-target="#egypt" type="button" role="tab" aria-controls="egypt" aria-selected="false"><span>{t('KSA')}</span></div>
                            </div>
                        </div>
                        <div className="tab-content " id="myTabContent">
                            <div className="tab-pane fade show active" id="UAE" role="tabpanel" aria-labelledby="UAE-tab">
                                <div className='addressContent'>
                                    <h6 className='adressHead'>{t('address')}</h6>
                                    <div className='d-flex justify-content-between align-items-center'>

                                        <p className='adressPar mb-0'>
                                            {i18n.language === 'ar' ? contactData?.uae_address_ar : contactData?.uae_address}
                                        </p>
                                        <svg data-bs-toggle="collapse" href="#uaeMap" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#A58C3B" class="bi bi-geo-fill" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z" />
                                        </svg>

                                    </div>
                                </div>
                                <div className='row'>
                                    {/* <div className='col'>
                                        <h6 className='adressHead'>{t('Mobile')}</h6>
                                        <a className='adressPar' href={`tel:${contactData?.uae_mobile}`}>{contactData?.uae_mobile}</a>
                                    </div> */}
                                    {/* <div className='col'>
                                        <h6 className='adressHead'>{t('telephone')}</h6>
                                        <a className='adressPar' href={`tel:${contactData?.uae_telephone}`}>{contactData?.uae_telephone}</a>
                                    </div> */}
                                    <div className='col'>
                                        <h6 className='adressHead'>{t('Email')}</h6>
                                        <a className='adressPar' href={`mailto:${contactData?.uae_email}`}>{contactData?.uae_email}</a>
                                    </div>
                                </div>
                                <div className='collapse' id='uaeMap'>

                                    <Map location={{ lat: Number(contactData?.uae_latitude), lng: Number(contactData?.uae_longitude) }} />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="egypt" role="tabpanel" aria-labelledby="egypt-tab"><div className='addressContent'>
                                <h6 className='adressHead'>{t('address')}</h6>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <p className='adressPar mb-0'>
                                        {i18n.language === 'ar' ? contactData?.eg_address_ar : contactData?.eg_address}
                                    </p>
                                    <svg data-bs-toggle="collapse" href="#egMap" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#A58C3B" class="bi bi-geo-fill" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z" />
                                    </svg>

                                </div>
                            </div>
                                <div className='row'>
                                    {/* <div className='col'>
                                        <h6 className='adressHead'>{t('Mobile')}</h6>
                                        <a className='adressPar' href={`tel:${contactData?.eg_mobile}`}>{contactData?.eg_mobile}</a>
                                    </div>
                                    <div className='col'>
                                        <h6 className='adressHead'>{t('telephone')}</h6>
                                        <a className='adressPar' href={`tel:${contactData?.eg_telephone}`}>{contactData?.eg_telephone}</a>
                                    </div> */}
                                    <div className='col'>
                                        <h6 className='adressHead'>{t('Email')}</h6>
                                        <a className='adressPar' href={`mailto:${contactData?.eg_email}`}>{contactData?.eg_email}</a>
                                    </div>

                                </div>
                                <div className='collapse' id='egMap'>
                                    <Map location={{ lat: Number(contactData?.eg_latitude), lng: Number(contactData?.eg_longitude) }} />
                                </div>

                            </div>
                        </div>

                    </div>
                </section>
                <section className='col-12 col-lg-5 formSection position-relative'>
                    <h2 className='contactHeade d-none d-lg-block'>
                        {t('lets')}   {t('connect')}
                    </h2>
                    <h2 className='contactHeade d-lg-none'>
                        {t('lets')}
                    </h2>
                    <h2 className='contactHeade d-lg-none'>
                        {t('connect')}
                    </h2>
                    <div className='adressPar'>
                        {t('Wed love to help by growing your business. Simply reach out to us.')}
                    </div>
                    <form onSubmit={contactSubmitHandler} ref={contactFormRef}>
                        <input type='text' className="input-field" placeholder={t('Full Name')} name='name' onChange={contactInputsHandler} />
                        <div className='errorText'>{errors?.name}</div>
                        <input type='email' className="input-field" placeholder={t('Email')} name='email' onChange={contactInputsHandler} />
                        <div className='errorText'>{errors?.email}</div>
                        <textarea className="text-field" placeholder={t('Your message')} name='message' onChange={contactInputsHandler} ></textarea>
                        <div className='errorText'>{errors?.message}</div>
                        <Recaptcha
                            ref={refRecaptcha}
                            sitekey={recaptcha_key}
                            secretkey={recaptcha_secretKey}
                            size={'invisible'}
                        />
                        <div className='d-flex justify-content-center justify-content-lg-start contactBtn'>
                            <button className='defaultBtn '>
                                {t('submit')}
                            </button>
                        </div>
                    </form>
                    <img src={ellipse} className='contactEllipse' alt='' />

                </section>

            </div>
        </div>
    )
}