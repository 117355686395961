
import { api_url } from "../config/config";
import { https } from "./../https";


export async function getHome() {
    return await https.get(`${api_url}/settings/home`);
  }

// get home first slide
export async function getHomeFirstSlide(){
    return await https.get(`${api_url}/slider`);
}

export async function getServices(){
    return await https.get(`${api_url}/services`);
}
//get testmonials
export async function getTestimonials(){
    return await https.get(`${api_url}/testimonials`);
}