import ellipse from '../../images/servicesEllipse.png';
import './services.css';
import React, { useEffect, useState } from "react";
import { WOW } from 'wowjs';
import MobileServices from './servicesMobile';
import { getServices } from '../../api/home';
import { filterLanguage } from '../../util/filterLanguage';
import parse from 'html-react-parser'
import { getServicesHeaders } from '../../api/pages';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

export default function Services() {
    const [showTabs, setShowtabs] = useState(false);
    const [serviceTitle, setserviceTitle] = useState('');
    const [serviceHeaders, setserviceHeaders] = useState({});
    const [services, setServices] = useState([]);
    const [serviceContent, setserviceContent] = useState('');
    let x = Array.from(document.querySelectorAll('.servicesNavtabs'));
    let { t } = useTranslation()

    function showContent(title, x) {
        setShowtabs(true);
        setserviceContent(x);
        setserviceTitle(title);
    };
    useEffect(() => {
        const wow = new WOW({ live: false });
        wow.init();
    }, [])
    useEffect(() => {
        if (showTabs) {
            x.map((item) => {
                item.classList.remove('servicesNavtabsLarge')
                item.classList.add('servicesNavtabsSmall')
                return true;
            })
        }
    //eslint-disable-next-line
    }, [showTabs]);

    // get main services details
    useEffect(() => {
        getServices().then((res) => {
            let services = res.data.data
            setServices(services)
        })
        getServicesHeaders().then((res) => {
            let serviceHeader = res.data.data
            setserviceHeaders(serviceHeader)
        })
    }, [])

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dubisign | {t('Services')}</title>
            </Helmet>
            <div className='mainWrapper position-relative contactUs d-none d-lg-block'>
                <h1 className='servicesHeadline serviceTitle wow animate__fadeInRight' data-wow-duration='2s' data-wow-itiration='2s'>{serviceTitle}</h1>
                <div className='row  mx-0 w-100  servicesContainer'>
                    <div className='col-2 col-md-4 col-xl-3 wow animate__fadeInUp' data-wow-duration='2s'>
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            {services && services.map((service, index) => {
                                return (
                                    <button key={index} onClick={() => showContent(service && service?.locales && service?.locales.length > 0 && filterLanguage(service)[0].title, service && service.locales && service.locales.length > 0 && parse(filterLanguage(service)[0].description))} className="d-flex align-items-center servicesNavtabs servicesNavtabsLarge" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                        <img src={service?.image.url} alt="" className='servicesIcon' />
                                        <span className='mx-2'>{service && service?.locales && service?.locales.length > 0 && filterLanguage(service)[0].title}</span>
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                    <div className='col-8  col-md-6 col-xl-7 wow animate__fadeInRight' data-wow-duration='2s'>
                        {!showTabs &&
                            <div>
                                <h1 className='servicesHeadline' >{serviceHeaders && serviceHeaders?.locales && serviceHeaders?.locales.length > 0 && filterLanguage(serviceHeaders)[0].title} </h1>
                                <p className='servicesPar'>
                                    {serviceHeaders && serviceHeaders?.locales && serviceHeaders?.locales.length > 0 && filterLanguage(serviceHeaders)[0].description}
                                </p>
                            </div>}
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade servicesContent wow animate__fadeInUp" data-wow-duration='2s' id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                {serviceContent}
                            </div>
                        </div>
                    </div>
                </div>
                <img src={ellipse} className='servicesEllipse' alt='' />

            </div>
            <MobileServices services={services} serviceHeaders={serviceHeaders} />
        </div>
    )
}