import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from '../src/Localization/en.json'
import translationAr from '../src/Localization/ar.json'
import LanguageDetector  from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: translationEn
  },
  ar: {
    translation: translationAr
  }
};

const { location } = window;
let lang = location.pathname.match(/\/ar\/|\/en\/|\/ar+$|\/en+$/)
if (!lang || !lang.length) {
    location.replace('/en' + location.pathname);
    lang = location.pathname.match(/\/ar\/|\/en\/|\/ar+$|\/en+$/)
}
localStorage.setItem('i18nextLng', lang[0].replaceAll('/', ''));

i18n
  .on('languageChanged', (lng) => {
    if (lng && !location.pathname.includes(`/${lng}/`)) {
      const newUrl = location.pathname.replace(/\/ar\/|\/en\/|\/ar+$|\/en+$/, `/${lng}/`);
      localStorage.setItem('i18nextLng', lng);
      location.replace(newUrl);
    }
  })
  .use(LanguageDetector)
  .use(initReactI18next) 
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng'),
    fallbackLng: "en",
    interpolation: {
      escapeValue: false 
    },
    react:{
        useSuspense:false
    }
  });

  export default i18n;
