import React, { useEffect, useState } from "react";
import './aboutUs.css';
import Ellipse from '../../images/Ellipse-about.png';
import aboutUs from '../../images/aboutus-ellipse.png';
// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import { Link } from 'react-router-dom';
import { WOW } from 'wowjs';
import { getAbout, getTeam } from '../../api/pages';
import { filterHeaders, filterLanguage } from '../../util/filterLanguage';
import { useTranslation } from 'react-i18next';
import leftArrow from '../../images/leftArrow.svg';
import rightArrow from '../../images/rightArrow.svg';
import { Helmet } from "react-helmet";


export default function AboutUs() {
    let [aboutData, setAboutData] = useState()
    let [testmonials, setTestmonials] = useState()
    let { t } = useTranslation()

    useEffect(() => {
        const wow = new WOW({ live: false });
        wow.init();
    }, [])
    
    useEffect(() => {
        getAbout().then((res) => {
            setAboutData(res.data.data)
        })
        getTeam().then((res) => {
            let testimonials = res.data.data
            setTestmonials(testimonials)
        })
    }, [])

    return (
        <div className=' aboutusWrapper position-relative contactUs'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dubisign | {t('About')}</title>
            </Helmet>
            <div className='mainWrapper'>
                <section className=' row  wow animate__fadeInDown' style={{ minHeight: '600px'}} data-wow-duration='2s'>
                    <div className='col-12 col-lg-5 '>
                        <h1 className='homeHeadline'>
                            {aboutData?.about_who_we_are && filterHeaders(aboutData?.about_who_we_are)[0].title}
                        </h1>
                        <div className='about-us-info'>
                            <p className='homeWord d-none d-lg-block'>
                                {aboutData?.about_who_we_are && filterHeaders(aboutData?.about_who_we_are)[0].description}
                            </p>
                            <p className='homeWord  d-lg-none'>
                                {aboutData?.about_who_we_are && filterHeaders(aboutData?.about_who_we_are)[0].description.substr(0, 193)}
                            </p>
                        </div>
                    </div>
                    <div className='col-12 col-lg-7'>
                        {aboutData?.about_who_we_are_image.webp &&
                            <img className='about-us-img w-100' src={aboutData?.about_who_we_are_image.webp} alt='' />
                        }
                    </div>
                    <div className='about-us-info  d-lg-none'>
                        <p className='homeWord mt-4'>
                            {aboutData?.about_who_we_are && filterHeaders(aboutData?.about_who_we_are)[0].description.substr(193, 500)}
                        </p>
                    </div>
                </section>
            </div>
            {/********* Vision Section ********** */}
            <div className='splitter'>
                <span>{aboutData?.about_our_vision && filterHeaders(aboutData?.about_our_vision)[0].title}</span>
            </div>
            <div className='mainWrapper'>
                <div className='position-relative about-second'>
                    <section className='row wow animate__fadeInDown' data-wow-duration='2s' >
                        <div className='col-12 col-lg-4 ' >
                            {aboutData?.about_our_vision_image.webp &&
                                <img className='w-100 visionImg' src={aboutData?.about_our_vision_image.webp} alt='' />
                            }
                        </div>
                        <div className='col-12 col-lg-7'>
                            <div className='about-us-info'>
                                <p className='homeWord'>
                                    {aboutData?.about_our_vision && filterHeaders(aboutData?.about_our_vision)[0].description}
                                </p>
                            </div>
                        </div>
                    </section>
                    <img src={Ellipse} className='aboutUs-Ellipse  d-lg-block' alt='' />
                </div>
            </div>
            <div className='splitter'>
                <span>{aboutData?.about_core_team && filterHeaders(aboutData?.about_core_team)[0].title}</span>
            </div>
            <div className='mainWrapper'>
                <section className='teamSection wow animate__fadeInDown' data-wow-duration='2s'>
                    <p className='homeWord d-none d-md-block'>
                        {aboutData?.about_core_team && filterHeaders(aboutData?.about_core_team)[0].description}
                    </p>
                    <div className='position-relative projectswiper'>
                        <div className='swiper-custom-button-prev'>
                            <img src={leftArrow} alt='' />
                        </div>
                        <div className='swiper-custom-button-next'>
                            <img src={rightArrow} alt='' />
                        </div>
                        <Swiper
                            loop={true}
                            centeredSlides={true}
                            initialSlide={1}
                            slidesPerView={1}
                            navigation={{
                                nextEl: ".swiper-custom-button-next",
                                prevEl: ".swiper-custom-button-prev",
                            }}
                            modules={[FreeMode, Autoplay, Navigation]}
                            className="team"
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,

                            }}
                            breakpoints={{
                                500: {
                                    slidesPerView: 1,
                                    spaceBetween: 5,
                                    // centeredSlides:true,/
                                    loop: false,
                                    initialSlide: 0
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 3,
                                    // centeredSlides:true,/
                                    loop: false,
                                    initialSlide: 0
                                },
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                    centeredSlides: true
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                    centeredSlides: true
                                },
                                1400: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                    centeredSlides: true
                                },
                                1500: {
                                    slidesPerView: 3,
                                    spaceBetween: 15,
                                    centeredSlides: true
                                }
                            }}
                        >
                            {testmonials && testmonials.length > 0 && testmonials.map((item, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className='d-flex flex-column w-100 justify-content-center align-items-center text-center'>
                                            <img src={item?.image?.webp} alt='' />
                                            <h6>{item && item?.locales && item?.locales.length > 0 && filterLanguage(item)[0].name}</h6>
                                            <span>{item && item?.locales && item?.locales.length > 0 && filterLanguage(item)[0].job}</span>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </section>
            </div>
            <div className='mainWrapper'>
                <section className='text-center position-relative growSection'>
                    <h1 className='homeHeadline wow animate__shakeX' data-wow-duration='2s'>
                        {aboutData?.about_grow_with_us && filterHeaders(aboutData?.about_grow_with_us)[0].title}
                    </h1>
                    <div className='homeWord pt-5 pb-5 '>
                        {aboutData?.about_grow_with_us && filterHeaders(aboutData?.about_grow_with_us)[0].description}

                    </div>
                    <Link to={`${process.env.REACT_APP_BASENAME}/contact-us`} className='defaultBtn'>
                        <span>{t('Grow Your Business')}</span>
                        <svg width="15" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.5 8.00012C0.5 7.71833 0.611942 7.44808 0.811199 7.24882C1.01046 7.04956 1.28071 6.93762 1.5625 6.93762L13.8726 6.93762L9.31025 2.37737C9.21146 2.27859 9.1331 2.16131 9.07964 2.03224C9.02617 1.90317 8.99866 1.76483 8.99866 1.62512C8.99866 1.48542 9.02617 1.34708 9.07964 1.21801C9.1331 1.08894 9.21146 0.971658 9.31025 0.872872C9.40904 0.774085 9.52631 0.695722 9.65538 0.642259C9.78446 0.588796 9.92279 0.56128 10.0625 0.56128C10.2022 0.56128 10.3405 0.588796 10.4696 0.642259C10.5987 0.695722 10.716 0.774085 10.8148 0.872872L17.1898 7.24787C17.2887 7.34657 17.3672 7.46382 17.4208 7.5929C17.4743 7.72198 17.5019 7.86037 17.5019 8.00012C17.5019 8.13988 17.4743 8.27826 17.4208 8.40734C17.3672 8.53643 17.2887 8.65367 17.1898 8.75237L10.8148 15.1274C10.716 15.2262 10.5987 15.3045 10.4696 15.358C10.3405 15.4114 10.2022 15.439 10.0625 15.439C9.92279 15.439 9.78446 15.4114 9.65539 15.358C9.52631 15.3045 9.40904 15.2262 9.31025 15.1274C9.11074 14.9279 8.99866 14.6573 8.99866 14.3751C8.99866 14.2354 9.02617 14.0971 9.07964 13.968C9.1331 13.8389 9.21146 13.7217 9.31025 13.6229L13.8726 9.06262L1.5625 9.06262C1.28071 9.06262 1.01046 8.95068 0.811199 8.75142C0.611942 8.55217 0.5 8.28191 0.5 8.00012Z" fill="white" />
                        </svg>
                    </Link>
                </section>
            </div>
            <img src={aboutUs} className='d-none d-lg-block aboutusEllipse' alt='' />
        </div>
    )
}