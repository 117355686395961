import header from '../../images/caseStudy.png';
import logo from './logo.png';
import work from './work.png';
import man from './man.png';
import chart from './chart.png';
import linechart from './Linechart.png';
import './projects.css';
import Timeline from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
export default function CaseStudy() {
    const groups = [{ id: 1, title: 'group 1' }, { id: 2, title: 'group 2' }]

    const items = [
        {
            id: 1,
            group: 1,
            title: 'item 1',
            start_time: moment(),
            end_time: moment().add(1, 'hour')
        },
        {
            id: 2,
            group: 2,
            title: 'item 2',
            start_time: moment().add(-0.5, 'hour'),
            end_time: moment().add(0.5, 'hour')
        },
        {
            id: 3,
            group: 1,
            title: 'item 3',
            start_time: moment().add(2, 'hour'),
            end_time: moment().add(3, 'hour')
        }
    ]

    return (
        <div>
                {/*********** header ********** */}
            <section className='modalHeader' style={{ backgroundImage: `url(${header})` }}>
                <div className='headerContent'>
                    <img src={logo} alt='logo' />
                    <p className='headerSubheading'>Subheading goes here</p>
                    <div className='firstLine'></div>
                    <div className='d-flex mt-2'>
                        <div className='secondLine'></div> <div className='thirdLine'></div>
                    </div>
                    <p className='projSummary'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id ut nibh mi commodo sollicitudin at morbi a. Mollis viverra a non duis ultricies erat.
                    </p>
                    <div className='d-flex fields mt-5'>
                        <span>Field 1</span>
                        <span>Field 2</span>
                        <span>Field 3</span>
                        <span>Field 4</span>
                    </div>
                </div>
            </section>

            <div className='gradientSplitter'>Description</div>
            {/* **************** Desciption  ***************** */}
            <section className='caseStudyWrapper'>
                <div className="rightimg">
                    <img src={work} />
                </div>
                <p>
                    How many times were you frustrated while looking
                    out for a good collection of programming/
                    algorithm/ interview questions? What did you
                    expect and what did you get? This portal has been
                    created to provide well written, well thought and
                    well explained solutions for selected questions.
                    An IIT Roorkee alumnus and founder of
                    GeeksforGeeks. He loves to solve programming
                    problems in most efficient ways. Apart from
                    GeeksforGeeks, he has worked with DE Shaw and
                    Co. as a software developer and JIIT Noida as
                    an assistant professor. It is a good platform
                    to learn programming. It is an educational
                    website. Prepare for the Recruitment drive of
                    product    based companies like Microsoft, Amazon,
                    Adobe etc with a free online placement
                    preparation course.
                    Prepare for the Recruitment drive of
                    product    based companies like Microsoft, Amazon,
                    Adobe etc with a free online placement
                    preparation course.
                </p>
            </section>

            {/* <Timeline
                groups={groups}
                items={items}
                defaultTimeStart={moment().add(-12, 'hour')}
                defaultTimeEnd={moment().add(12, 'hour')}
                /> */}

            {/*  ******************** project overview ******************* */}
            <div className='gradientSplitter'>Project Overview</div>
            <section className='caseStudyWrapper'>
                <div className='problems'>
                    <h2>Problems</h2>
                    <ul>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                    </ul>
                </div>
                <div className='problems'>
                    <h2>Solutions</h2>
                    <ul>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                    </ul>
                </div>

            </section>
            {/*  ******************** Approach ******************* */}
            <div className='gradientSplitter'>The Approach</div>
            <section className='caseStudyWrapper'>
                <div className="leftimg">
                    <img src={work} alt='approach'/>
                </div>
                <p>
                    How many times were you frustrated while looking
                    out for a good collection of programming/
                    algorithm/ interview questions? What did you
                    expect and what did you get? This portal has been
                    created to provide well written, well thought and
                    well explained solutions for selected questions.
                    An IIT Roorkee alumnus and founder of
                    GeeksforGeeks. He loves to solve programming
                    problems in most efficient ways. Apart from
                    GeeksforGeeks, he has worked with DE Shaw and
                    Co. as a software developer and JIIT Noida as
                    an assistant professor. It is a good platform
                    to learn programming. It is an educational
                    website. Prepare for the Recruitment drive of
                    product    based companies like Microsoft, Amazon,
                    Adobe etc with a free online placement
                    preparation course.
                    Prepare for the Recruitment drive of
                    product    based companies like Microsoft, Amazon,
                    Adobe etc with a free online placement
                    preparation course.
                </p>
                <div className='row'>
                    <img src={man} className='manImg w-100'/>
                </div>
            </section>
            {/*  ******************** Results ******************* */}
            <div className='gradientSplitter'>Results</div>
            <section className='caseStudyWrapper'>
                <div className='d-flex flex-column'>
                    <span className='results'>RESULT 1</span>
                    <span className='results align-self-end'>RESULT 2</span>
                    <span className='results '>RESULT 2</span>
                </div>
                <p className='text-center'>
                    Sed posuere dolor eu tincidunt donec. Sit velit ultricies vitae erat odio venenatis. Eget sem faucibus tellus, purus molestie mi urna nulla dolor. Accumsan vulputate augue orci vitae mattis pharetra diam. Volutpat morbi nunc sed sagittis. Nulla maecenas consequat auctor tellus cursus nibh fames. Scelerisque amet praesent nec faucibus eget neque at. Vitae venenatis in risus, faucibus nunc. Tellus, ac a laoreet nisi pulvinar. 
                </p>
                <div className='row mt-5 mb-5'>
                    <div className='col-5'>
                        <img src={chart}/>
                    </div>
                    <div className='col-5'>
                        <img src={linechart}/>
                    </div>
                </div>
            </section>
            {/*  ******************** Footer ******************* */}

          <div className='caseStudyFooter'>
                <section className='caseStudyWrapper'>
                    <div className='BrandName'>
                        <h2>Brand Name</h2>
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                    </div>
                    <div className='logoFooter'>
                    <img src={logo} alt='logo' className='footerimg' />
                    <span className='footerField'>FIELD</span>

                    </div>
                 
                </section>
          </div>

        </div>
    )
}